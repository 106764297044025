import * as React from 'react'
import Layout from '@components/layout'
import { useTranslation } from 'react-i18next'
import Box from '@common/box'

const Home = (props) => {  
  const {t} = useTranslation()

  function delayAndGo() {
    setTimeout(() => {
      if (typeof window !== 'undefined') {
        window.location = '/';
      }
      return null;
    }, 250);
  }

  delayAndGo();

  return (
    <Layout>
      <Box>
        <h1>{t('redirect.title')}</h1>
      </Box>
    </Layout>
  )
}

export default Home